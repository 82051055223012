
import { computed, defineComponent, ref } from '@nuxtjs/composition-api';

import { onClickOutside, useToggle } from '@vueuse/core';

export default defineComponent({
  props: {
    value: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    options: {
      type: Array,
      default() {
        return [];
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: null,
    },
    error: {
      type: String,
      default: null,
    },
    itemId: {
      type: String,
      default: 'id',
    },
    itemText: {
      type: String,
      default: 'text',
    },
  },
  setup(props, { emit }) {
    const target = ref(null);
    const isMenuOpen = ref(false);

    const toggleMenu = useToggle(isMenuOpen);

    const valueLocal = computed({
      get() {
        const activeOption = props.options.find(
          (option) => option[props.itemId] === props.value,
        );
        return activeOption ? activeOption[props.itemText] : '';
      },
      set(newValue) {
        emit('input', newValue);
      },
    });

    const selectOption = (option) => {
      valueLocal.value = option[props.itemId];
      toggleMenu(false);
    };

    onClickOutside(target, () => {
      toggleMenu(false);
    });

    return {
      target,
      valueLocal,
      isMenuOpen,
      toggleMenu,
      selectOption,
    };
  },
});

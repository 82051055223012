
import {
  computed,
  defineComponent,
  ref,
  useContext,
  useFetch,
  watch,
} from '@nuxtjs/composition-api';
import { useFetchPage } from '@webplatform/nuxt-cms-js-sdk';
import { prepareBreadcrumbs } from '~/helpers';

export default defineComponent({
  setup() {
    const { route, i18n } = useContext();
    const { page, fetchPage } = useFetchPage(route.value.params.slug, {
      lang: i18n.locale,
    });

    const pageSidebar = ref(null);
    const pageBlocks = ref(null);

    useFetch(async () => {
      await fetchPage();
    });

    watch(page, () => {
      if (page.value?.blocks) {
        const iSidebar = page.value.blocks.findIndex(
          (b) => b.type === 'json' && b.content.type === 'app-left-sidebar',
        );

        if (iSidebar !== -1)
          pageSidebar.value = page.value.blocks
            .splice(iSidebar, 1)
            .pop().content.data;

        pageBlocks.value = page.value.blocks;
      }
    });

    const breadcrumbs = computed(() => [
      ...(page.value?.breadcrumbs || []).map(prepareBreadcrumbs),
      {
        text: page.value?.title,
      },
    ]);

    return {
      page,
      pageBlocks,
      pageSidebar,
      breadcrumbs,
    };
  },
  head: {},
});
